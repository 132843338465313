<template>
  <div class="dg-ppm__form-container">
    <div class="dg-ppm__form-container__hero">
      <PPMBackBtn />
      <h1 class="dg-ppm__form-container__hero__title">{{ $t("ppm.create.title") }}</h1>
      <p class="dg-ppm__form-container__hero__desc">{{ $t("ppm.create.desc") }}</p>
    </div>
    <PPMForm :hasFiles="false" />
  </div>
</template>

<script>
import PPMForm from "../components/PPMCreate/PPMForm.vue";
import PPMBackBtn from "../components/PPMBackBtn.vue";

export default {
  name: "PPMCreate",
  components: {
    PPMForm,
    PPMBackBtn,
  },
};
</script>
